import * as THREE from 'three';

export function createSphere(radius, segments, color, wireframe) {
  const geometry = new THREE.SphereGeometry(radius, segments, segments);
  const material = new THREE.MeshBasicMaterial({ color, wireframe });
  return new THREE.Mesh(geometry, material);
}

export function createTextLabel(text, fontSize = 30) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = `${fontSize}px Arial`;
  context.fillStyle = 'white';

  canvas.width = context.measureText(text).width;
  canvas.height = fontSize * 1.5;

  context.fillStyle = 'white';
  context.fillText(text, 0, fontSize);
  context.font = `${fontSize}px Arial`;

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = 'white';
  context.fillText(text, 0, fontSize);

  const texture = new THREE.CanvasTexture(canvas);
  const material = new THREE.SpriteMaterial({ map: texture });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(canvas.width / 100, canvas.height / 100, 1);

  return sprite;
}
