import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { createSphere } from './threeHelpers';

class OrbitalController {
  constructor(mountRef, navigate) {
    this.mountRef = mountRef;
    this.navigate = navigate;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer();
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.isHoveringOverMoon = false;
    this.DEFAULT_ANIMATION_SPEED = 0.002;
    this.animationSpeed = this.DEFAULT_ANIMATION_SPEED;
    this.moons = [];
    this.init();
  }

  init() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.mountRef.current.appendChild(this.renderer.domElement);

    window.addEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.addEventListener('click', this.onClick.bind(this));

    this.createScene();
    this.animate();
  }

  createScene() {
    const globe = createSphere(7, 16, 0x00ff00, true);
    this.scene.add(globe);
    this.globe = globe;

    const stars = this.createStars(500, 0.5);
    this.scene.add(stars);

    this.camera.position.z = 25;

    const directoryOptions = [
      { name: 'About', path: '/about' },
      { name: 'Projects', path: '/projects' },
      { name: 'Library', path: '/library' },
      { name: 'Contact', path: '/contact' },
    ];

    directoryOptions.forEach((option, i) => {
      const moon = createSphere(0.75, 4, 0xc0c0c0, true);
      this.scene.add(moon);

      this.moons.push({
        mesh: moon,
        angle: Math.random() * Math.PI * 2,
        speed: 0.001 + Math.random() * 0.004,
        distance: 7 + i * 3,
        inclination: (Math.random() * Math.PI) / 6 - Math.PI / 12,
      });
    });
  }

  createStars(amount, size) {
    const starsGeometry = new THREE.BufferGeometry();
    const starsMaterial = new THREE.PointsMaterial({
      color: 0xffffff,
      size: size,
      sizeAttenuation: true,
    });
    const starPositions = [];

    for (let i = 0; i < amount; i++) {
      const [x, y, z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(2000));
      starPositions.push(x, y, z);
    }

    starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starPositions, 3));
    return new THREE.Points(starsGeometry, starsMaterial);
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onMouseMove(event) {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);

    const isCurrentlyHoveringMoon = intersects.some(intersect => intersect.object.userData.isMoon);

    if (isCurrentlyHoveringMoon) {
      this.animationSpeed = 0.0005;
      this.isHoveringOverMoon = true;
    } else if (this.isHoveringOverMoon && !isCurrentlyHoveringMoon) {
      this.animationSpeed = this.DEFAULT_ANIMATION_SPEED;
      this.isHoveringOverMoon = false;
    }

    this.moons.forEach(moon => {
      moon.mesh.material.color.setHex(0xc0c0c0);
    });

    for (let i = 0; i < intersects.length; i++) {
      const object = intersects[i].object;
      if (object.userData.isMoon) {
        this.animationSpeed = 0.0001;
        object.material.color.setHex(0xff0000);
        break;
      }
    }
  }

  onClick(event) {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children, true);

    for (let i = 0; i < intersects.length; i++) {
      const object = intersects[i].object;
      if (object.userData.isMoon) {
        this.explodeMoon(object);
        break;
      }
    }
  }

  explodeMoon(moon) {
    // Create a blue flash sphere
    const flashGeometry = new THREE.SphereGeometry(1, 32, 32);
    const flashMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
    const flash = new THREE.Mesh(flashGeometry, flashMaterial);
    flash.position.copy(moon.position);
    this.scene.add(flash);
  
    // Remove the blue flash after a short duration
    setTimeout(() => {
      this.scene.remove(flash);
  
      // Remove the moon from the scene
      this.scene.remove(moon);
  
      // Create explosion particles
      const explosionGeometry = new THREE.BufferGeometry();
      const positions = [];
      const particleCount = 100;
  
      for (let i = 0; i < particleCount; i++) {
        positions.push(moon.position.x, moon.position.y, moon.position.z);
      }
  
      explosionGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
      const explosionMaterial = new THREE.PointsMaterial({ color: 0xff0000, size: 0.1 }); // Change color to red
  
      const explosion = new THREE.Points(explosionGeometry, explosionMaterial);
      this.scene.add(explosion);
  
      // Animate explosion particles
      const animateExplosion = () => {
        const positions = explosionGeometry.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          positions[i] += (Math.random() - 0.5) * 0.5;
          positions[i + 1] += (Math.random() - 0.5) * 0.5;
          positions[i + 2] += (Math.random() - 0.5) * 0.5;
        }
        explosionGeometry.attributes.position.needsUpdate = true;
      };
  
      const explosionDuration = 1000; // 1 second
      const startTime = Date.now();
  
      const explosionAnimation = () => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < explosionDuration) {
          animateExplosion();
          requestAnimationFrame(explosionAnimation);
        } else {
          this.scene.remove(explosion);
        }
      };
  
      explosionAnimation();
    }, 100); // Flash duration of 100 milliseconds
  }
  

  animate() {
    requestAnimationFrame(this.animate.bind(this));
  
    const time = Date.now() * 0.0005;
  
    // Oscillate camera position
    this.camera.position.x += Math.sin(time) * 0.01;
    this.camera.position.y += Math.cos(time) * 0.01;
    this.camera.position.z += Math.sin(time) * 0.01;
  
    // Oscillate camera rotation
    this.camera.rotation.x += Math.sin(time) * 0.001;
    this.camera.rotation.y += Math.cos(time) * 0.001;
    this.camera.rotation.z += Math.sin(time) * 0.001;
  
    // Rotate the globe
    this.globe.rotation.y += this.animationSpeed;
  
    // Update moon positions
    this.moons.forEach(moon => {
      moon.mesh.userData.isMoon = true;
      moon.angle += (moon.speed * this.animationSpeed) / this.DEFAULT_ANIMATION_SPEED;
  
      const x = Math.cos(moon.angle) * moon.distance;
      const z = Math.sin(moon.angle) * moon.distance * Math.cos(moon.inclination);
      const y = Math.sin(moon.angle) * moon.distance * Math.sin(moon.inclination);
  
      moon.mesh.position.set(x, y, z);
    });
  
    this.renderer.render(this.scene, this.camera);
  }

  cleanup() {
    if (this.mountRef.current) {
      this.mountRef.current.removeChild(this.renderer.domElement);
    }
    window.removeEventListener('resize', this.onWindowResize.bind(this));
    window.removeEventListener('mousemove', this.onMouseMove.bind(this));
    window.removeEventListener('click', this.onClick.bind(this));
  }
}

const OrbitalNavigator = () => {
  const mountRef = useRef(null);
  const navigate = useNavigate();
  const controllerRef = useRef(null);

  useEffect(() => {
    controllerRef.current = new OrbitalController(mountRef, navigate);

    return () => {
      controllerRef.current.cleanup();
    };
  }, [navigate]);

  return <div ref={mountRef} />;
};

export default OrbitalNavigator;